import { Divider } from '@mui/material';
import { speakerMembers } from '../constant';
import PeopleList from '../PeopleList';


const Speakers = () => {
  const gridSizeList = speakerMembers.map(() => 3);
  return (
    <>
      <PeopleList
        pageTitle='Conference Speakers'
        members={speakerMembers}
        gridSizeList={gridSizeList}
      />
      <Divider sx={{
        color:'#BDBDBD',
        width: '100%',
        border: '1px solid'
      }}/>
    </>
  );
};
export default Speakers;
