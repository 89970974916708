import { useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

// mui
import Stack from '@mui/material/Stack';

// components
import Banner from './Banner';
import EventSchedule from './EventSchedule';
import Committee from './Committee';
import FAQs from './FAQs';
import ReconRegistration from './Registeration';
import Speakers from './Speakers';
import axios from 'axios';
import PaymentDialog, { PaymentPayloadInterface } from './Registeration/PaymentDialog';
import { Alert, Box, Snackbar, Typography } from '@mui/material';
import Quotes from './Quotes';
import About from './About';
import ContactUs from './ContactUs';
import GetStartPage from './GetStartPage';
import SpeakersCarousel from './SpeakersCarousel';
import AngleLeftIcon from '../../assets/svg/AngleLeftIcon';
import styles from './styles';
export const BASE_URL = process.env.REACT_APP_API_URL;

const Recon = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [confirmationPayload, setConfirmationPayload] = useState<PaymentPayloadInterface>({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    paymentStatus: '',
    status: ''
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageOpen, setMessageOpen] = useState(false);

  const aboutRef = useRef(null);
  const scheduleRef = useRef(null);
  const committeeRef = useRef(null);
  const speakersRef = useRef(null);
  const testimonalsRef = useRef(null);
  const faqRef = useRef(null);
  const reconFormRef = useRef(null);
  const contactusRef = useRef(null);

  const handleCloseSnackbar = () => {
    setMessageOpen(false);
    setMessage('');
  };

  useEffect(() => {
    const getStripeInfo = async () => {
      try {
        const res = await axios.get(
          `${BASE_URL}/email-service/recon-bookings-stripe/${searchParams.get('checkout')}`
        );
        if (res.data.Status === 200 && res.data.Success) {
          const firstName =
            res.data.Success?.customer_name?.split(' ')[0] ?? res.data.Success?.customer_name;
          const lastName = res.data.Success?.customer_name?.split(' ')[1] ?? '';
          setConfirmationPayload({
            firstName: firstName,
            lastName: lastName,
            email: res.data.Success?.customer_email,
            company: res.data.Success?.customer_company,
            paymentStatus: res.data?.Success.payment_status,
            status: res.data.Success?.status
          });
        } else {
          setConfirmationPayload({
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            paymentStatus: '',
            status: ''
          });
        }
      } catch (err) {
        setConfirmationPayload({
          firstName: '',
          lastName: '',
          email: '',
          company: '',
          paymentStatus: '',
          status: ''
        });
      }
      setDialogOpen(true);
    };
    if (searchParams.get('checkout')) {
      getStripeInfo();
    }
  }, [searchParams]);

  const scrollToSection = (section: string) => {
    setTimeout(() => {
      switch (section) {
        case '/recon':
          window.scrollTo(0, 0);
          break;
        case '/recon/about':
          if (aboutRef && aboutRef.current) {
            // @ts-ignore
            aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/agenda':
          if (scheduleRef && scheduleRef.current) {
            // @ts-ignore
            scheduleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/testimonials':
          if (testimonalsRef && testimonalsRef.current) {
            // @ts-ignore
            testimonalsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/committee':
          if (committeeRef && committeeRef.current) {
            // @ts-ignore
            committeeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/speakers':
          if (speakersRef && speakersRef.current) {
            // @ts-ignore
            speakersRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/faq':
          if (faqRef && faqRef.current) {
            // @ts-ignore
            faqRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/register':
          if (reconFormRef && reconFormRef.current) {
            // @ts-ignore
            reconFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        case '/recon/contactus':
          if (contactusRef && contactusRef.current) {
            // @ts-ignore
            contactusRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
          break;
        default:
          window.scrollTo(0, 0);
      }
    }, 300)
  };

  useEffect(() => {
    const url = window.location.pathname;
    scrollToSection(url);
  }, []);

  useEffect(() => {
    scrollToSection(location.pathname);
  }, [location]);

  return (
    <>
      <Stack>
        <Banner />
        <Box
          sx={styles.guideButtonWrapper}
          onClick={() => {
            scrollToSection('/recon/register');
          }}>
          <AngleLeftIcon sx={styles.icon} />
          <Typography
            sx={styles.guideText}
            onClick={() => {
              scrollToSection('/recon/register');
            }}>
            Register
          </Typography>
        </Box>
        <SpeakersCarousel />
        <Stack ref={aboutRef} />
        <Stack ref={scheduleRef} />
        <EventSchedule />
        <About />
        <Stack ref={testimonalsRef} />
        <Quotes />
        <Stack ref={speakersRef} />
        <Speakers />
        <Stack ref={committeeRef} />
        <Committee />
        <Stack ref={reconFormRef} />
        <ReconRegistration />
        <Stack ref={faqRef} />
        <FAQs />
        <Stack ref={contactusRef} />
        <ContactUs />
        <GetStartPage />
      </Stack>
      <PaymentDialog
        firstName={confirmationPayload.firstName}
        lastName={confirmationPayload.lastName}
        email={confirmationPayload.email}
        company={confirmationPayload.company}
        paymentStatus={confirmationPayload.paymentStatus}
        status={confirmationPayload.status}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        setMessage={setMessage}
        setMessageOpen={setMessageOpen}
      />
      <Snackbar
        open={messageOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        sx={{ width: { mobile: '100%', laptop: '30%' } }}>
        <Alert onClose={handleCloseSnackbar} severity='success' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Recon;
